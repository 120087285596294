<template>
  <div>
    <div class="row mt-48">
      <div class="col-md-4 offset-md-2">
        <div class="card card-custom gutter-b">
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-12">
                <router-link to="/prealert/add">
                  <h1>You Shop, We Ship</h1>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-custom gutter-b">
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-12">
                <router-link to="/prealert/add-shipment">
                  <h1>We Grab, We Ship</h1>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  h1{
    font-weight: 400 !important;
  }
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Shipment" }]);
  },
};
</script>
